import React, { useState } from 'react';

export const GlobalContext = React.createContext({});

export const GlobalProvider = ({ children }) => {
  const [pathBeforeRedirect, setPathBeforeRedirect] = useState(null);

  return <GlobalContext.Provider
    value={{
      pathBeforeRedirect,
      setPathBeforeRedirect
    }}>
    {children}
  </GlobalContext.Provider>
};
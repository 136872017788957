import React from 'react';

import { ApiProvider }    from './src/context/Api';
import { GlobalProvider } from './src/context/Global';

export const wrapRootElement = ({ element }) => {
  return (
    <ApiProvider>
      <GlobalProvider>
        {element}
      </GlobalProvider>
    </ApiProvider>
  )
};